var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _vm.isLoading
          ? _c("div", [_c("Loading")], 1)
          : _c("div", { attrs: { id: "body-content-area" } }, [
              _c("div", { attrs: { id: "pagelayout" } }, [
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "block" }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.handleFormSubmit.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("div", [
                            _vm.error !== null
                              ? _c("div", { staticClass: "has-text-danger" }, [
                                  _vm._v(" " + _vm._s(_vm.error) + " "),
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "field" }, [
                              _vm._m(1),
                              _c("div", { staticClass: "control" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "radio",
                                    staticStyle: { width: "100%" },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.input.ItemType,
                                          expression: "input.ItemType",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "ItemType",
                                        id: "item-grouping",
                                        value: "1",
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.input.ItemType,
                                          "1"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.input,
                                            "ItemType",
                                            "1"
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" Constant Item Grouping "),
                                  ]
                                ),
                                _c(
                                  "label",
                                  {
                                    staticClass: "radio",
                                    staticStyle: {
                                      width: "100%",
                                      "margin-left": "0",
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.input.ItemType,
                                          expression: "input.ItemType",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "ItemType",
                                        id: "item-grouping",
                                        value: "4",
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.input.ItemType,
                                          "4"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.input,
                                            "ItemType",
                                            "4"
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" Dynamic Item Grouping "),
                                  ]
                                ),
                                _c(
                                  "label",
                                  {
                                    staticClass: "radio",
                                    staticStyle: {
                                      width: "100%",
                                      "margin-left": "0",
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.input.ItemType,
                                          expression: "input.ItemType",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "ItemType",
                                        id: "item-grouping",
                                        value: "6",
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.input.ItemType,
                                          "6"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.input,
                                            "ItemType",
                                            "6"
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" Vendor Grouping "),
                                  ]
                                ),
                                _c(
                                  "label",
                                  {
                                    staticClass: "radio",
                                    staticStyle: {
                                      width: "100%",
                                      "margin-left": "0",
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.input.ItemType,
                                          expression: "input.ItemType",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "ItemType",
                                        id: "document-list",
                                        value: "2",
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.input.ItemType,
                                          "2"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.input,
                                            "ItemType",
                                            "2"
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" Document List "),
                                  ]
                                ),
                                _c(
                                  "label",
                                  {
                                    staticClass: "radio",
                                    staticStyle: {
                                      width: "100%",
                                      "margin-left": "0",
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.input.ItemType,
                                          expression: "input.ItemType",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "ItemType",
                                        id: "page",
                                        value: "3",
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.input.ItemType,
                                          "3"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.input,
                                            "ItemType",
                                            "3"
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" Page "),
                                  ]
                                ),
                              ]),
                            ]),
                            parseInt(_vm.input.ItemType) === 4
                              ? _c("div", { staticClass: "field" }, [
                                  _vm._m(2),
                                  _c(
                                    "div",
                                    { staticClass: "control" },
                                    [
                                      _c("Select", {
                                        attrs: {
                                          options: {
                                            1: "Carrier List",
                                            2: "Plan List",
                                          },
                                          default: 1,
                                          identifier: "dynamicItem",
                                        },
                                        on: {
                                          onchange: _vm.handleSelectChange,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "field" }, [
                              _vm._m(3),
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.input.Label,
                                      expression: "input.Label",
                                    },
                                  ],
                                  staticClass: "input",
                                  attrs: {
                                    type: "text",
                                    name: "name",
                                    disabled:
                                      parseInt(_vm.input.ItemType) === 2,
                                  },
                                  domProps: { value: _vm.input.Label },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.input,
                                        "Label",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _vm.parentOptions
                              ? _c("div", { staticClass: "field" }, [
                                  _vm._m(4),
                                  _c(
                                    "div",
                                    { staticClass: "control" },
                                    [
                                      _c("SelectParentMenuItem", {
                                        ref: "parentOptions",
                                        attrs: {
                                          options: _vm.parentOptions,
                                          default: _vm.input.ParentId,
                                          identifier: "parents",
                                        },
                                        on: {
                                          onchange: _vm.handleSelectChange,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showReportOptions,
                                    expression: "showReportOptions",
                                  },
                                ],
                                staticClass: "field",
                              },
                              [
                                _vm._m(5),
                                _c(
                                  "div",
                                  { staticClass: "control" },
                                  [
                                    _c("Select", {
                                      ref: "reportOptions",
                                      staticStyle: { "max-width": "50%" },
                                      attrs: {
                                        options: _vm.reportOptions,
                                        default: _vm.input.ReportCode,
                                        identifier: "reportType",
                                      },
                                      on: { onchange: _vm.handleSelectChange },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showPageOptions,
                                    expression: "showPageOptions",
                                  },
                                ],
                                staticClass: "field",
                              },
                              [
                                _vm._m(6),
                                _c(
                                  "div",
                                  { staticClass: "control" },
                                  [
                                    _c("Select", {
                                      ref: "pageOptions",
                                      staticStyle: { "max-width": "50%" },
                                      attrs: {
                                        options: _vm.destinationOptions,
                                        default: _vm.input.Route,
                                        identifier: "destinations",
                                      },
                                      on: { onchange: _vm.handleSelectChange },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "field" }, [
                              _vm._m(7),
                              _c(
                                "div",
                                { staticClass: "control" },
                                [
                                  _c("Select", {
                                    ref: "iconOptions",
                                    staticStyle: { "max-width": "50%" },
                                    attrs: {
                                      options: _vm.iconOptions,
                                      default: _vm.input.IconType,
                                      disabled: _vm.isIconOptionsDisabled,
                                      identifier: "icons",
                                    },
                                    on: { onchange: _vm.handleSelectChange },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticStyle: { "padding-top": "20px" },
                              attrs: { id: "buttongroup" },
                            },
                            [
                              _c("div", { staticClass: "field is-grouped" }, [
                                _c("div", { staticClass: "control" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "button is-accent has-text-white",
                                      staticStyle: {
                                        visibility: "visible!important",
                                      },
                                      attrs: {
                                        disabled: !_vm.$hasPermissions(
                                          _vm.clientSession,
                                          ["CLIENT_MENU"],
                                          2
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.handleFormSubmit.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Save")]
                                  ),
                                ]),
                                _c("div", { staticClass: "control" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "button is-light",
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.back()
                                        },
                                      },
                                    },
                                    [_vm._v("Cancel")]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", {
                    staticClass: "column",
                    staticStyle: { "max-width": "60%" },
                  }),
                ]),
              ]),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Menu Item Details"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Item Type "),
      _c(
        "span",
        {
          staticClass: "has-text-danger",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Dynamic Item "),
      _c(
        "span",
        {
          staticClass: "has-text-danger",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Label "),
      _c(
        "span",
        {
          staticClass: "has-text-danger",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Display Under "),
      _c(
        "span",
        {
          staticClass: "has-text-danger",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Report Type "),
      _c(
        "span",
        {
          staticClass: "has-text-danger",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Destination "),
      _c(
        "span",
        {
          staticClass: "has-text-danger",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Icon "),
      _c(
        "span",
        {
          staticClass: "has-text-danger",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }